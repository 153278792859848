import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';


export default {
  name: 'uploadProvisions',
  components: {
    appStrings,
    DatePicker,
  },
  props: ['rowData'],
  watch: {
    currentPage: function() {
      this.mainSearch();
    },
    perPage: function() {
      this.currentPage = 1;
      this.mainSearch();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      uploadProvDetailsData: [],
      uploadProvDetailsFields: [
        {
          key: 'ledger_name'
        },
        {
          key: 'provision_period',
          label: 'Period'
        },
        {
          key: 'Uploaded_by',
          label: 'Uploaded by'
        },
        {
          key: 'approval_status',
          label:'Status'
        },
        {
          key: 'view_details'
        },
        {
          key: 'debit'
        },
        {
          key: 'credit'
        },
        {
          key: 'file_name'
        }
      ],
      showAlert: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      uploadedBy: {
        text: null,
        value: null
      },
      period: null,
      uploadProvStatus: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      defaultValue:{
        value: null,
        text: null
      },
      showUploadBatchDetailsModal:false,
      showLegalEntityModal:false,
      openModalType:'uploadProv',
      showAddProvisonModalFlag:false,
      showExcelUploadModal:false,
    };
  },
  mounted() {
    if(this.rowData){
    this.provisionsData(this.rowData.prov_hdr_id)
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showUploadBatchDetailsModal && !this.showLegalEntityModal) {
        //   this.showAddProvisonModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
          // this.showHideUploadBatchDetailsModal(true)
        } else if (
          actionName === 'upload' && !this.showAddProvisonModalFlag && !this.showLegalEntityModal
        ) {
          // if (this.poHdrId) {
            this.showExcelUploadModal = true;
          // } else {
          //   alert('Kindly press search button');
          // }
        }
      }
    });
  },
  methods: {
    provisionsData(id) {
      const payload = {
        prov_hdr_id:id
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisonalDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            // this.uploadProvData = resp.data.data.page;
            // this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {},


  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
