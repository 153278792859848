// import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import legalEntityListVset from '../../../codAdmin/legalEntityListVset';


export default {
  name: 'uploadProvisionFile',
  components: {
    DatePicker,
    legalEntityListVset
  },
  props: [],
  watch: {
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    uploadperiod:{required}
  },
  data() {
    return {
      legalEntity: {
        text: null,
        value: null
      },
      defaultValue: {
        text: null,
        value: null
      },
      file: null,
      showValueSetModal:false,
      uploadperiod:null,
      showLegalEntityModal:false,
      openModalType:'uploadProv'
    };
  },
  mounted() {},
//   created() {
//     this.eventBus.$on('uploadFileLegalEntity', legalEntity => {
//       this.legalEntity = {
//         text: legalEntity.text,
//         value: legalEntity.value
//       };
//       this.showLegalEntityModal = false;
//     });
//     this.eventBus.$on('uploadFileBankAcc', bankAcc => {
//       this.bankAccNum = {
//         text: bankAcc.text,
//         value: bankAcc.value
//       };
//       this.showBankAccNumModal = false;
//     });
//   },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    clearFile() {
      this.file = null;
    },
    showHideLegalEntityModal(flag) {
      this.showLegalEntityModal = flag;
    },
    uploadFile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.file) {
          alert('Kindly select a file.');
          return;
        } else {
          let formData = new FormData();
          formData.append('file', this.file);
          formData.append('le_id', this.legalEntity.value);
          formData.append('bank_account_id', this.bankAccNum.value);
          this.loader = true;
          this.$store
            .dispatch(
              this.uploadType === 'bankStatement'
                ? 'codReco/uploadBankStmt'
                : 'codReco/uploadMisDoc',
              formData
            )
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 201) {
                this.$bvToast.toast(response.data.message, {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
              } else {
                this.$bvToast.toast(response.response.data.message, {
                  title: 'Alert',
                  variant: 'danger',
                  solid: true
                });
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    openValueSetModal(vsetCode) {
        this.vsetCode = vsetCode;
        this.showValueSetModal = true;
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },
  
      selectedvalueSet(item) {
          if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
            this.legalEntity = {
              value: item.value_set_dtl_id,
              text: item.value_code
            };
          } 
          this.showValueSetModal = false;
        },
  
      closeValueSetModal() {
        this.showValueSetModal = false;
      },
      clearVsetValues(vsetCode) {
          switch(vsetCode){
            case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
              this.legalEntity = this.defaultValue
              break;
          }
        },
        mapSelectedVsetData(item) {
          if (item.name === 'uploadProvisions') {
            this.legalEntity = {
              text: item.le_name,
              value: item.le_id
            };
            this.showLegalEntityModal = false;
          } 
        },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
